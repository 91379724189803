export function genInitialData() {
  return {
    url: null,
    eventList: null,
    description: '',
    secret: null,
    active: true,
    authUser: null,
    authPassword: null,
    credentials: null
  }
}
