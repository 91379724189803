<template>
  <th-wrapper
    :title="$t('pages.webhooks.form.labels.events.list')"
    class="m-0 p-0"
  >
    <th-datatable
      ref="table"
      class="webhooks-events w-full flex justify-between p-0"
      do-meta-check
      expanding-row
      multiple-select
      resource="webhookEvents"
      show-filter
      sortable
      sort-type="remote"
      :headers-filterable="false"
      :apply-cell-class-name="applyCellClassName"
      :custom-fetch-id="parentEventId"
      :headers="headers"
      :labels="{ table: { headers: { operations: '' } } }"
      :meta-options="resourceQuery"
      :resource-query="resourceQuery"
      :search-filters="filtersList"
      :show-expanding-row-icon="true"
      @loaded="isLogEmpty = !$event.length"
      @search-filter-submit="fetchEvents"
      @search-filter="updateFilter"
      @select-all="handleSelectAll"
      @selection-change="handleSelectionChange"
    >
      <!-- Resend all events button -->
      <template #actions>
        <!-- Replay events button -->
        <div v-if="!isLogEmpty">
          <el-button
            plain
            type="primary"
            class="p-2"
            :loading="allEventsReplay"
            :disabled="bulkReplayButton.disabled"
            @click="handleBulkEventReplay"
          >
            <!--el-icon-refresh-left-->
            <el-icon v-if="!allEventsReplay">
              <RefreshLeft />
            </el-icon>
            {{ bulkReplayButton.label }}
          </el-button>
        </div>
      </template>
      <template #chips-viewer>
        <chips-viewer
          class="w-max"
          text-field-name="q"
          :filters="filtersList"
          :model-value="filterObjectWithData"
          @before-remove-tag="handleFilterRemove"
        />
      </template>

      <!-- Resend event button -->
      <template #operations="{ row }">
        <div class="text-right">
          <el-button
            plain
            size="small"
            type="primary"
            class="p-2"
            :loading="currentEventReplay === row.id"
            @click.stop="handleEventReplay(row.id, row)"
          >
            <el-icon v-if="!currentEventReplay !== row.id">
              <RefreshLeft />
            </el-icon>
            {{ $t('pages.webhooks.form.labels.resend') }}
          </el-button>
        </div>
      </template>
      <!-- expanding event list -->
      <template #expanding-row="{ row }">
        <webhook-events-expanding-row :row="row" />
      </template>

      <!-- No data tenplate -->
      <template #no-data>
        <div class="p-6" v-text="$t('common.data.no_data')" />
      </template>
    </th-datatable>
  </th-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import flush from 'just-flush'

import ChipsViewer from '@/components/filter-header/chips-viewer'
import WebhookEventsExpandingRow from './webhook-events-expanding-row'

export default {
  components: {
    ChipsViewer,
    WebhookEventsExpandingRow
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    supportedEvents: {
      type: Object,
      default: () => ({ events: [] })
    },
    parentEventId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      eventsList: [],
      isLogEmpty: false,
      selectedItems: [],
      selectAll: false,
      filterObject: {},
      currentEventReplay: null,
      allEventsReplay: false
    }
  },
  computed: {
    ...mapGetters({
      pageSize: 'Config/getPageSizes'
    }),
    headers() {
      return [
        {
          field: 'entity',
          label: this.$t('pages.webhooks.form.labels.entity'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'eventType',
          label: this.$t('common.forms.labels.type'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'version',
          label: this.$t('pages.webhooks.form.labels.version'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row) => `v${row.version}`
        },
        {
          field: 'createdAt',
          label: this.$t('pages.webhooks.form.labels.created_at'),
          minWidth: 120,
          truncate: true,
          formatter: (row) =>
            row.createdAt ? this.$date.formatDateTime(row.createdAt) : '-'
        }
      ]
    },
    filtersList() {
      return [
        {
          name: 'sentSuccess',
          type: 'select',
          label: this.$t('pages.webhooks.form.labels.sent_success'),
          options: this.yesNoOptions,
          filterable: true
        },
        {
          name: 'entity',
          type: 'select',
          label: this.$t('pages.webhooks.form.labels.entity'),
          options: this.entityOptions,
          filterable: true
        },
        {
          name: 'eventType',
          type: 'select',
          label: this.$t('common.forms.labels.type'),
          options: this.eventTypeOptions,
          filterable: true
        },
        {
          name: 'version',
          type: 'select',
          label: this.$t('pages.webhooks.form.labels.version'),
          options: this.versionOptions,
          filterable: true
        },
        {
          name: 'created_at',
          type: 'daterange',
          label: this.$t('pages.customers.all.filters.created_at.label'),
          preventDefaultDates: true,
          noFutureDates: true,
          minDate: this.minPossibleSelectedDate,
          formatValue: (value) => this.$date.formatDateRange(value),
          modifyFilter: (filterObject) => ({
            start: filterObject.start,
            end: filterObject.end
          })
        }
      ]
    },
    minPossibleSelectedDate() {
      //Date object represents the date 30 days ago starting from midnight
      const date = new Date()
      date.setDate(date.getDate() - 30)
      date.setHours(0, 0, 0, 0)
      return date
    },
    resourceQuery() {
      //make sure the end date is not in the future (API does not accept it)
      const end =
        this.queryValues.end && new Date(this.queryValues.end) > new Date()
          ? new Date().toISOString()
          : this.queryValues.end
      return {
        ...this.queryValues,
        end,
        limit: this.pageSize.main
      }
    },
    bulkReplayButton() {
      return {
        label: this.selectAll
          ? this.$t('pages.webhooks.form.labels.resend_all')
          : this.$t('pages.webhooks.form.labels.resend_selected'),
        disabled: !(this.selectAll || this.selectedItems.length)
      }
    },
    queryValues() {
      return flush({
        sentSuccess: safeGet(this.filterObject, 'sentSuccess'),
        start: safeGet(this.filterObject, 'created_at.start'),
        end: safeGet(this.filterObject, 'created_at.end'),
        entity: safeGet(this.filterObject, 'entity'),
        eventType: safeGet(this.filterObject, 'eventType'),
        version: safeGet(this.filterObject, 'version')
      })
    },
    filterObjectWithData() {
      return this.filtersList.map((filter) => {
        const value = safeGet(this.queryValues, filter.name)
        if (value) {
          return {
            ...filter,
            value
          }
        }
        return filter
      })
    },
    parsedFilter() {
      const filterValues = {
        ...this.queryValues
      }
      if (this.queryValues.start && this.queryValues.end) {
        filterValues.created_at = {
          start: this.queryValues.start,
          end: this.queryValues.end
        }
      }
      return filterValues
    },
    yesNoOptions() {
      return [
        {
          label: this.$t('common.interactions.buttons.yes'),
          value: 'true'
        },
        {
          label: this.$t('common.interactions.buttons.no'),
          value: 'false'
        }
      ]
    },
    eventOptions() {
      return Object.keys(this.supportedEvents.events || {}).reduce(
        (options, key) => {
          options.entities.push(key)
          const item = this.supportedEvents.events[key][0]
          if (!options.eventTypes.includes(item.eventType))
            options.eventTypes.push(item.eventType)
          if (!options.versions.includes(item.version))
            options.versions.push(item.version)
          return options
        },
        { entities: [], eventTypes: [], versions: [] }
      )
    },
    entityOptions() {
      return this.eventOptions.entities.map((value) => ({
        value,
        label: value
      }))
    },
    eventTypeOptions() {
      return this.eventOptions.eventTypes.map((value) => ({
        value,
        label: value
      }))
    },
    versionOptions() {
      return this.eventOptions.versions.map((value) => ({
        value: `${value}`,
        label: `v${value}`
      }))
    }
  },
  methods: {
    handleFilterRemove(filter = {}) {
      this.filterObject[filter.name] = filter.value
      this.fetchEvents()
    },
    updateFilter(filterObject = {}) {
      this.filterObject = filterObject
    },
    async fetchEvents() {
      this.$refs.table.refresh()
    },
    async handleEventReplay(id) {
      //Replay single event
      try {
        this.currentEventReplay = id
        const query = { eventId: [id] }
        await th.webhookEvents().replay(this.parentEventId, query)
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.currentEventReplay = null
        this.fetchEvents()
      }
    },
    async handleBulkEventReplay() {
      //replay bulk event by filter
      try {
        this.allEventsReplay = true
        const query = { ...this.queryValues }
        if (this.selectedItems.length) {
          query.eventId = this.selectedItems
        }
        await th.webhookEvents().replay(this.parentEventId, query)
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.allEventsReplay = false
        this.fetchEvents()
      }
    },
    handleSelectionChange(values) {
      this.selectedItems = values.map(({ id }) => id)
    },
    handleSelectAll(values) {
      this.selectAll = !!values.length
    },
    applyCellClassName({ columnIndex, row }) {
      if (columnIndex === 0) {
        return `border-l-4 ${
          row.sentSuccessfully ? 'border-green-500' : 'border-red-500'
        } clean-cell`
      }
    }
  }
}
</script>
<style scoped>
.list {
  height: 450px;
}
.webhooks-events :deep(tr::first-line th) {
  padding: 0 !important;
  background-color: red;
}
.webhooks-events :deep(.clean-cell) {
  border-bottom-color: var(--el-border-color-main) !important;
}
.webhooks-events :deep(.el-table__expanded-cell:hover) {
  background-color: white !important;
}
</style>
