import baseModel from '@/models/base-model-new'

export default {
  setup(model) {
    const methods = {
      parseDataBeforeSave(data) {
        const { secret, credentials, authUser, authPassword, ...payload } = data
        if (authUser && authPassword) {
          payload.authUser = authUser
          payload.authPassword = authPassword
        }
        return payload
      }
    }
    return baseModel.setup(model, 'webhooks', methods)
  }
}
