<template>
  <div class="flex" data-testid="log-table">
    <div class="w-1/2 pr-6 col-max-height">
      <!-- Request payload -->
      <el-row
        class="border-l-8 cursor-pointer border-blue-500 mb-6 hover:blue-600"
        :class="{
          'item-selected': row.id === selectedItem
        }"
        type="flex"
        justify="space-evenly"
        :gutter="10"
        @click="selectedItem = item.id"
      >
        <div class="item first-item flex items-center w-full">
          <el-col :span="5">
            <span>{{ $t('pages.webhooks.event-list.request.payload') }}</span>
          </el-col>
          <el-col :span="18" class="text-right">
            <div class="item-icon">
              <el-icon
                class="pl-2 outline-none cursor-pointer focus:text-blue-600 hover:text-blue-600"
                :title="$t('common.interactions.copy.clipboard')"
                @click="handleCopy(row.requestPayload)"
              >
                <CopyDocument
              /></el-icon>
            </div>
          </el-col>
        </div>
      </el-row>
      <!-- Responses payloads -->
      <el-row
        v-for="(log, logIndex) in logs"
        :key="log.id"
        class="border-l-8 cursor-pointer"
        type="flex"
        justify="space-evenly"
        :class="{
          'border-red-500': !log.sentSuccessfully,
          'border-green-500': log.sentSuccessfully,
          'item-selected': log.id === selectedItem
        }"
        :gutter="10"
        @click="selectedItem = log.id"
      >
        <div
          class="item flex items-center w-full"
          :class="{ 'first-item': logIndex === 0 }"
        >
          <el-col :span="5">
            <span>{{ formatCreatedAt(log) }}</span>
          </el-col>
          <el-col :span="18" class="text-right">
            <div class="item-icon">
              <el-icon
                class="pl-2 outline-none cursor-pointer focus:text-blue-600 hover:text-blue-600"
                :title="$t('common.interactions.copy.clipboard')"
                @click.stop="handleCopy(log.response)"
              >
                <CopyDocument
              /></el-icon>
            </div>
          </el-col>
        </div>
      </el-row>
    </div>
    <div class="w-1/2 col-max-height">
      <pre data-testid="payload">{{ selectedJson }}</pre>
    </div>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import { copyToClipboard } from '@/utils/dom'

export default {
  props: {
    row: {
      type: Object,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      item: {},
      selectedItem: ''
    }
  },
  computed: {
    id() {
      return this.row?.id
    },
    webhookId() {
      return this.row?.webhook?.id
    },
    logs() {
      return this.item?.logs || []
    },
    selectedJson() {
      //show request payload if no log is selected
      if (this.selectedItem === this.id) {
        return this.item.requestPayload
      }
      //show selected log
      return this.logs.find(({ id }) => id === this.selectedItem)?.response
    }
  },
  async mounted() {
    // get event
    const { data = {} } = await th.webhookEvents().get(this.webhookId, this.id)
    this.item = data
    this.selectedItem = this.item.id
  },
  methods: {
    formatCreatedAt(row) {
      return row.createdAt ? this.$date.formatDateTime(row.createdAt) : '--'
    },
    async handleCopy(payload) {
      try {
        await copyToClipboard(JSON.stringify(payload))
        this.$message({
          type: 'success',
          message: this.$t('common.interactions.copied.clipboard')
        })
      } catch (err) {
        this.$message({
          type: 'error',
          message: err.message
        })
      }
    }
  }
}
</script>

<style scoped>
.item {
  font-size: 14px;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}

.first-item {
  border-top: 1px solid var(--border-color);
}

.item-selected {
  background-color: var(--select-option-bg-hover);
}

.item-icon :deep(i) {
  width: 24px;
}

.col-max-height {
  height: 200px;
  overflow-x: hidden;
}
</style>
