<template>
  <el-form
    ref="form"
    v-loading="loading"
    :validate-on-rule-change="false"
    class="p-6"
  >
    <!-- webhook information -->
    <webhook-information
      ref="webhook-information"
      v-model="current"
      :supported-events="supportedEvents"
    />
    <!-- webhook events -->
    <webhook-events
      v-if="!isNew"
      v-model="current"
      :supported-events="supportedEvents"
      :parent-event-id="id"
    />
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import webhookModel from '../model/webhook-model'
import { genInitialData } from '../helpers'
import WebhookInformation from './webhook-information'
import WebhookEvents from './webhook-events'

export default {
  components: { WebhookInformation, WebhookEvents },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    return webhookModel.setup(genInitialData())
  },
  data() {
    return {
      supportedEvents: {}
    }
  },
  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration',
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    })
  },
  async mounted() {
    this.id = this.$route.params.id
    await this.fetch()
    await this.fetchResources()
    this.sync()
  },
  methods: {
    async fetchResources() {
      try {
        const { supportedEvents = [] } = await this.$resourceFetch(
          'supportedEvents'
        )
        const entities = []
        const events = {}
        supportedEvents.forEach((supportedEvent) => {
          if (!entities.includes(supportedEvent.entity)) {
            entities.push(supportedEvent.entity)
            events[supportedEvent.entity] = []
          }
          events[supportedEvent.entity].push(supportedEvent)
        })
        this.supportedEvents = { entities, events }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t(
            'pages.settings.users.edit.form.errors.fetch.resources.code_XXX.content'
          )
        })
      }
    },
    async handleSubmit() {
      //validate the form
      const isValid = await this.validate()
      if (!isValid) return
      //save
      const isNew = this.isNew

      const resource = this.$t('common.resource.webhook.singular')
      const { error } = await this.save()
      if (error) {
        //save fail
        const errorMessage = isNew
          ? this.$t('common.error.action.create.single', { resource })
          : this.$t('common.error.action.update.single', { resource })

        this.$logException(error, {
          message: errorMessage,
          trackError: false
        })
        return
      }

      //save success
      const successMessage = isNew
        ? this.$t('common.success.action.create.single', { resource })
        : this.$t('common.success.action.update.single', { resource })

      this.$message({
        type: 'success',
        message: successMessage
      })

      if (isNew) {
        if (this.navigationAfterCreation === 'edit' && this.id) {
          this.routeTo(this.id)
        } else {
          this.routeTo()
        }
      }
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(this.current.url)
      if (confirm) this.delete()
    },
    async delete() {
      const { error } = await this.deleteModel()
      if (error) {
        //Delete failed
        const errorMessage = this.$t('common.error.action.delete.single', {
          resource: this.$t('common.resource.webhook.singular')
        })

        this.$logException(error, { message: errorMessage })
        return
      }

      //Delete success
      this.routeTo()
    },
    routeTo(path) {
      this.reset() //this will make model clean (not dirty)
      const routePath = `/settings/webhooks${path ? '/' + path : ''}`
      this.$router.push(routePath)
    },
    async validate() {
      //validate webhook information
      return await this.$refs['webhook-information'].validate()
    }
  }
}
</script>
