<template>
  <th-wrapper :title="$t('common.titles.general_info.title')">
    <!-- Center actions -->
    <template #center>
      <!-- Actively usable -->
      <el-checkbox v-model="form.active">
        {{ $t('pages.webhooks.form.labels.active') }}
      </el-checkbox>
    </template>

    <el-form ref="form" :model="form" :rules="rules">
      <el-row :gutter="10">
        <el-col :md="12">
          <!-- URL -->
          <el-col>
            <el-form-item
              prop="url"
              :label="$t('pages.webhooks.form.labels.url')"
            >
              <el-input
                id="url"
                v-model="form.url"
                :placeholder="$t('pages.webhooks.form.labels.url')"
              />
            </el-form-item>
          </el-col>
          <!-- Description -->
          <el-col>
            <el-form-item
              prop="description"
              :label="$t('pages.webhooks.form.labels.description')"
            >
              <el-input
                id="description"
                v-model="form.description"
                type="textarea"
                :rows="3"
                :placeholder="$t('pages.webhooks.form.labels.description')"
              />
            </el-form-item>
          </el-col>
          <!-- Secret -->
          <el-col>
            <el-form-item
              v-if="!isNew"
              prop="secret"
              :label="$t('pages.webhooks.form.labels.secret')"
            >
              <el-input
                id="secret"
                v-model="form.secret"
                disabled
                :placeholder="$t('pages.webhooks.form.labels.secret')"
              />
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :md="12">
          <!-- Events -->
          <el-col>
            <el-form-item
              prop="eventList"
              :label="$t('pages.webhooks.form.labels.events.list')"
            >
              <el-select
                id="eventList"
                v-model="form.eventList"
                v-cancel-read-only
                multiple
                filterable
                required
                class="w-full"
              >
                <el-option-group
                  v-for="entity in supportedEvents.entities"
                  :key="entity"
                  :label="$t(`pages.webhooks.form.labels.entities.${entity}`)"
                >
                  <el-option
                    v-for="item in supportedEvents.events[entity]"
                    :key="item.event"
                    :label="
                      $t(`pages.webhooks.form.labels.events.${item.event}`)
                    "
                    :value="item.event"
                  />
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- Authorization user -->
          <el-col>
            <el-form-item>
              <th-input-title
                :title="`${$t(
                  'pages.webhooks.form.labels.authUser'
                )}${alreadySetText}`"
                :info="
                  authorizationExists
                    ? $t('pages.webhooks.form.auth.text')
                    : null
                "
                prop="authUser"
              />
              <el-input
                id="authUser"
                v-model="form.authUser"
                data-testid="authUser"
                :placeholder="$t('pages.webhooks.form.labels.authUser')"
              />
            </el-form-item>
          </el-col>
          <!-- Authorization password -->
          <el-col>
            <el-form-item
              prop="authPassword"
              :label="`${$t(
                'pages.webhooks.form.labels.authPassword'
              )}${alreadySetText}`"
            >
              <el-input
                id="authPassword"
                v-model="form.authPassword"
                :placeholder="$t('pages.webhooks.form.labels.authPassword')"
              />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    supportedEvents: {
      type: Object,
      default: () => ({ entities: [], events: [] })
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    isNew() {
      // cheat
      if (this.$route.params.id && this.$route.params.id === 'new') return true

      return !this.$route.params.id
    },
    authorizationExists() {
      return !!this.modelValue.credentials
    },
    alreadySetText() {
      return this.authorizationExists
        ? ` (${this.$t('pages.webhooks.form.auth.already_set')})`
        : ''
    },
    authIsRequired() {
      return !!this.form.authPassword || !!this.form.authUser
    },
    rules() {
      return {
        url: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          },
          { validator: this.validateURL, trigger: 'blur' }
        ],
        eventList: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          },
          { validator: this.validateEvents, trigger: 'blur' }
        ],
        authUser: [
          {
            required: this.authIsRequired,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ],
        authPassword: [
          {
            required: this.authIsRequired,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    },
    validateEvents(rule, value, callback) {
      this.form.eventList.length
        ? callback()
        : callback(
            new Error(this.$t('common.forms.rules.field_warnings.required'))
          )
    },
    validateURL(rule, value, callback) {
      var pattern = new RegExp(
        '^(https?:\\/\\/)' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '.*$',
        'i'
      ) // fragment locator
      pattern.test(value)
        ? callback()
        : callback(
            new Error(
              this.$t(
                'pages.settings.custom_dashboards.dashboards.form.url.placeholder'
              )
            )
          )
    }
  }
}
</script>
